<template>
    <ContentWrapper>
        <breadcrumb v-if="delivery.amount > 0" icon="fad fa-exchange-alt" :name="$t('growing.component.storage.deliveries.heading') + ' #' + delivery.id" />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="card card-default animated-fast fadeInUp">
                        <div class="card-body p-0">

                        </div>
                        <div class="card-header">
                            {{$t('wallet.view.transaction.details')}}
                        </div>
                        <div class="card-body">
                            <table class="table table-hover">
                                <tbody>
                                <tr>
                                    <td>{{ $t('growing.component.storage.deliveries.created_at') }}</td>
                                    <td>{{ delivery.created_at }}</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('growing.component.storage.deliveries.amount') }}</td>
                                    <td>{{ delivery.amount }}g</td>
                                </tr>
                                <tr>
                                    <td>{{ $t('growing.component.storage.deliveries.shipped_at') }}</td>
                                    <td v-if="delivery.shipped_at">{{ delivery.shipped_at }}</td>
                                    <td v-if="delivery.refunded">
                                      {{ $t('growing.component.storage.deliveries.refunded') }}
                                    </td>
                                    <td v-else-if="delivery.shipped_at">{{ delivery.shipped_at }}</td>
                                    <td v-else>
                                      {{ $t('growing.component.storage.deliveries.waiting_for_shipment') }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>{{ $t('growing.component.storage.deliveries.tracking_code') }}</td>
                                    <td><a :href="delivery.tracking_code_url" target="_blank" rel="noreferrer">{{ delivery.tracking_code }}</a></td>
                                </tr>
                                <tr>
                                    <td>{{ $t('growing.component.storage.deliveries.address') }}</td>
                                    <td>
                                        {{ delivery.address.firstname }} {{ delivery.address.lastname }}<br>
                                        {{ delivery.address.street }} {{ delivery.address.house_number }}<br>
                                        {{ delivery.address.zip }} {{ delivery.address.town }}<br>
                                        {{ delivery.address.state }}, {{ delivery.address.country }}<br>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <b-btn class="mt-5 float-right" :to="{name: 'Growing:Storage'}" variant="primary">{{$t('growing.view.storage.deliveries.back')}} </b-btn>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </ContentWrapper>
</template>
<style lang="scss">

</style>
<script>

    export default {
        data() {
            return {
                delivery: {
                    address: {
                        country: '',
                        firstname: '',
                        house_number: '',
                        lastname: '',
                        state: '',
                        street: '',
                        town: '',
                        zip: '',
                    },
                    amount: '',
                    country: '',
                    created_at: '',
                    id: '',
                    shipped_at: '',
                    tracking_code: '',
                    tracking_code_url: '',
                },
            }
        },
        mounted() {
            this.$api.get('/growing/storage/delivery/' + this.$route.params.id).then(response => {
                this.delivery = response.data;
            }).catch(() => {
                this.$router.push({name: 'Growing:Storage'})
            })
        },
        methods: {}
    }
</script>
